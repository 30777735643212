<template>
  <div class="search input-text--search">
    <transition name="el-fade-in-linear">
      <div
        v-if="isShowBtn"
        :class="[buttonClass, { buttonMargin: buttonMargin }]"
      >
        <iq-button
          icon="DriverPlus"
          color="main-dark"
          :size="buttonSize"
          :disabled="!isTerminalId"
          @onClick="handleAddDay"
        >
          {{ buttonText }}
        </iq-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { DIALOG_ADD_QUOTA, DIALOG_ADD_SAMPLE } from '@/constants/dialogs'
import { GET_IS_MOBILE } from '@/store/actions'
import { GET_QUOTAS } from '@/views/home/store/actions'
import { GET_SAMPLES } from '@/views/quota-samples/store/actions'
import { mapGetters } from 'vuex'
import IqButton from '@/UI/buttons/IqButton'

export default {
  name: 'AddQuotaButton',
  components: { IqButton },
  props: {
    isTerminalId: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      quotasList: GET_QUOTAS,
      samplesList: GET_SAMPLES,
      isMobile: GET_IS_MOBILE,
    }),
    isHomePage() {
      return this.$route.name === 'Home'
    },
    buttonClass() {
      return this.isMobile ? 'mobileSearchButton' : 'm-r-16'
    },
    buttonMargin() {
      return window.innerWidth < '767'
    },
    buttonSize() {
      return this.isMobile ? 'fullWidthMedium' : ''
    },
    isShowBtn() {
      return this.isHomePage
        ? Object.keys(this.quotasList).length > 0
        : this.samplesList.length > 0
    },
    buttonText() {
      return this.isHomePage
        ? 'Добавить квоту по культуре'
        : 'Добавить шаблон суточной квоты'
    },
  },
  methods: {
    handleAddDay() {
      if (this.isHomePage) {
        this.setDialog({ name: DIALOG_ADD_QUOTA, visible: true })
      } else {
        this.setDialog({ name: DIALOG_ADD_SAMPLE, visible: true })
      }
    },
  },
}
</script>

<style lang="sass">
.input-text--search
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  width: 100%
  flex: 1 0 100%
  .mobileSearchButton
    display: flex
    justify-content: center
    width: 100%
  .buttonMargin
    margin-top: 6px
    margin-right: 0 !important
  & .el-icon-search
    color: #9EAFE1
  & input
    outline: none
    background: #FFFFFF
    border: $inputs-border
    border-radius: $inputs-border-radius
    padding: 2px 12px 1px

    font-weight: 300
    font-size: $fs-14
    line-height: 25px
    color: #B0B0B0
    width: 100%
    max-width: 266px
    +to(768px)
      max-width: 100%
.search
  display: flex
  justify-content: flex-end
  align-items: center
  &__input-wrap
    width: 100%
    max-width: 266px
    +to(1024px)
      max-width: 100%
</style>
